import { render, staticRenderFns } from "./addPanel.vue?vue&type=template&id=f53aa1fc&scoped=true&"
import script from "./addPanel.vue?vue&type=script&lang=js&"
export * from "./addPanel.vue?vue&type=script&lang=js&"
import style0 from "./addPanel.vue?vue&type=style&index=0&id=f53aa1fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f53aa1fc",
  null
  
)

component.options.__file = "addPanel.vue"
export default component.exports